import UserResource from 'common/services/resources/user.js'
import Subscribe from './subscribe/subscribe.vue'
import ForgotPassword from './forgot-password/forgot-password.vue'
import Market from '../home/market/market.vue'
import WindowService from 'common/services/window.js'
import LanguageService from 'common/services/language.js'
import store from 'vuex_path/store'

export default {
	props: ['user','regionIndex'],
	components: { Subscribe, Market, ForgotPassword },
	data:
		function () {
			return {
					CONSTANTS:
					{
						SELECT_REGION : this.$gettext('Select a region'),
						TITLE : this.$gettext((store.state.variantManager.getSubscribe().title?store.state.variantManager.getSubscribe().title:'Your new comfort of life')),
						MOBILE_LOGIN: this.$gettext(this.$store.state.variantManager.class.login.appTitle?this.$store.state.variantManager.class.login.appTitle:'Your new comfort of life'),
						ACTIVATE_ERROR : this.$gettext('The account is not activated'),
						SUBSCRIBE:_upperFirst(this.$gettext('sign up')),
						FORGOT_YOUR_PASSWORD: _upperFirst(this.$gettext('forgot your password')),

					},
					userLogin:
					{
						username:'',
						password:'',
						hidePassword: true,
						rememberMe: true
					},
					selectedIndexRegion: '1',
					regions: LanguageService.CONSTANTS.REGIONS,
					extend:false,
					loading:false,
					forgotPasswordModal: false,
					selectedRegion: {}
			}
		},
    computed: {
      rightPanelClassObject: function () {
        return {
          'regular-mode': UserResource.isRegular(),
          'extend':this.eventHub.rightPanel.extend,
          'minimize':this.eventHub.rightPanel.minimize,
          'init': this.eventHub.rightPanel.init
        }
      }
    },
    created: function () {
		this.eventHub.$emit('hideNavbar');
		this.eventHub.$on('setSelectedIndexRegion',this.setSelectedIndexRegion);
		this.eventHub.$on('setUserLogin',this.setUserLogin);
		this.eventHub.$on('forgotPasswordValidate',this.forgotPasswordValidate);
		this.eventHub.$on('loginFinished',this.loginFinished);
		this.init();
	},
    destroyed: function () {
        this.eventHub.$off('setSelectedIndexRegion', this.setSelectedIndexRegion);
		this.eventHub.$off('setUserLogin', this.setUserLogin);
		this.eventHub.$off('forgotPasswordValidate', this.forgotPasswordValidate);
		this.eventHub.$off('loginFinished',this.loginFinished);
    },	
	methods: {
		init: function(){
			if(UserResource.getMqtt()!=null && !this.inApp){
				this.$router.push('/home/rooms');
				return false;
			}
            if(this.user)
            {
				this.userLogin.username = (this.$route.query.username?this.$route.query.username:this.user.username);	
				this.userLogin.password = this.user.password;	
                this.userLogin.hidePassword = true;
				this.userLogin.rememberMe = true;
			}
			//If region query from url
			if(this.$route.query.region)
			{
				this.selectedIndexRegion = LanguageService.getIndexFromUrl(this.$route.query.region);	
			}
			else
			{
				this.selectedIndexRegion = (this.regionIndex!=undefined?this.regionIndex:LanguageService.getIndexFromUrl((this.inApp?'eu':undefined)));
			}
			this.selectedRegion = this.regions[this.selectedIndexRegion];
		},

		login: function(){
			this.loading=true;
			this.eventHub.$emit('login', this.userLogin, this.selectedIndexRegion);
		},
		
		loginFinished: function(){
			this.loading=false;
		},

		onSubscribe: function()
		{
			this.$router.push('/subscribe');
		},
		
		setUserLogin: function(userLogin)
		{
			this.userLogin.username = userLogin.username;	
			this.userLogin.password = userLogin.password;	
			this.userLogin.hidePassword = userLogin.hidePassword;	
			this.userLogin.rememberMe = userLogin.rememberMe;	
		},

		executeMinimizeRightPanel: function()
		{
			this.extend=true;
			this.eventHub.$emit('setHideContent', true);
			this.eventHub.$emit('setMinimizeRightPanel', true);
		},
		clickOnLogo: function()
		{
			this.eventHub.$emit('initRightPanel');
		},
		forgotPasswordValidate: function()
		{
			this.forgotPasswordModal = false;
		},
		openDownloadPage: function(){
			WindowService.openDownloadApp('_blank',this.inApp);
		},
		setSelectedIndexRegion: function(selectedIndexRegion){
			this.selectedIndexRegion = selectedIndexRegion;
		},
		selectIndexRegion: function(){
			if(!this.inApp && !LanguageService.isDev())
			{
				window.open(this.regions[this.selectedIndexRegion].url,'_self');
			}
			else
			{
				this.selectedRegion = this.regions[this.selectedIndexRegion];
			}
		}
	}
}
